import {SpacingType} from '@nib-components/theme';
import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveSpaceProp} from './../../utils';
import {getSpacingFromTheme, Theme} from '../../spacing/core';

export function getSpacingValues(values: SpacingType) {
  return function ({theme}: {theme: Theme}) {
    return getSpacingFromTheme(values, theme);
  };
}

export const gap = () => css`
  ${({gap}: {gap?: ResponsiveSpaceProp}) =>
    map(
      gap,
      (val: SpacingType) =>
        val != undefined &&
        css`
          gap: ${getSpacingValues(val)};
        `
    )};
`;
