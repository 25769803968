import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {standardFocusStyleDeclarations} from '@nib-components/theme';
import {SearchSystemIcon} from '@nib/icons';
import {PrimaryButton} from '@nib-components/button';
import {Inline, Container, breakpoint, px, py, mr} from '@nib/layout';
import Logo from '@nib-components/logo';

import Ribbon from './Ribbon';
import variantConfig from '../config';
import {HeaderConfigType} from '../config/types';
import {SmallHeader} from '../SmallHeader';

import {
  HeaderWrapper,
  HeaderContainer,
  LogoLink,
  HeaderContact,
  PhoneNumber,
  PhoneIcon,
  HeaderLinks,
  SearchLink,
  HeaderNav,
  NavMenu,
  baseNavLinkStyles,
  NavLinkItem,
  variationValues,
  SEARCH_URL
} from '../shared';

const SmallHeaderBelowXl = styled(SmallHeader)`
  ${breakpoint('xl')`
    // Styled components has a serious issue with client-side hydration style order.
    // See issue: https://github.com/styled-components/styled-components/issues/3482
    // This is our way of compensating for unpredictable style order.
    &&& {
      display: none;
    }
  `}
`;

const HeaderButton = styled(PrimaryButton)`
  ${mr(5)};

  ${breakpoint('xl', 'xxl')`
    ${mr(3)};
  `}

  &:focus {
    ${standardFocusStyleDeclarations};
  }
`;

const NavLink = styled.a`
  ${baseNavLinkStyles};
  ${py(6)};
  ${px(5)};
  font-size: 18px;

  ${breakpoint('xl', 'xxl')`
    font-size: 1rem;
    ${py(5)};
    ${px(3)};
  `}
`;

const PhoneNumberSpan = styled.span`
  unicode-bidi: plaintext;
`;

export interface HeaderProps {
  variation?: variationValues;
  config?: HeaderConfigType;
  enableSearch?: boolean;
  hidePhoneNumber?: boolean;
  isCorporate?: boolean;
  withAbsoluteLinks?: boolean;
  absoluteBaseUrl?: string;
  invertSmallHeader?: boolean;
  [key: string]: unknown;
}

export const HeaderTop: React.FC<HeaderProps> = props => {
  const {variation = 'arhi', config, enableSearch = false, hidePhoneNumber = false, isCorporate, withAbsoluteLinks, absoluteBaseUrl, ...otherProps} = props;
  const dataSource = config ? config : variantConfig[variation]({withAbsoluteLinks, absoluteBaseUrl});
  const {id, logoUrl, callToAction, phoneNumber, corporatePhoneNumber, ribbon} = dataSource;
  const defaultOrCorporatePhoneNumber = isCorporate && corporatePhoneNumber ? corporatePhoneNumber : phoneNumber;
  const logoLink = logoUrl ? logoUrl : '/';

  const CustomLogo = dataSource?.logoComponent;
  const customLogoLabel = dataSource?.logoLabel;
  const themeContext = useContext(ThemeContext);
  const logoLabel = `${themeContext.id} Logo`;

  return (
    <>
      <HeaderWrapper id={`${id}-top`} {...otherProps}>
        {ribbon && ribbon.length > 0 && <Ribbon data={ribbon} />}
        <HeaderContainer>
          <LogoLink href={logoLink} id="ga-inlink-logo-homepage-desktop" aria-label={customLogoLabel ? customLogoLabel : logoLabel}>
            {CustomLogo ? <CustomLogo size="header" /> : <Logo size="header" />}
          </LogoLink>
          {defaultOrCorporatePhoneNumber && !hidePhoneNumber && (
            <HeaderContact>
              <PhoneNumber
                href={`tel:${defaultOrCorporatePhoneNumber && defaultOrCorporatePhoneNumber.replace(/\s+/g, '')}`}
                id={`ga-nav-${variation !== 'arhi' ? `${variation}-` : ''}call-desktop`}
                className="js-phone-number-call-tracking t-header-phone-number"
                aria-label="Call nib"
              >
                <Inline space={2}>
                  <PhoneIcon />
                  <PhoneNumberSpan>{defaultOrCorporatePhoneNumber}</PhoneNumberSpan>
                </Inline>
              </PhoneNumber>
            </HeaderContact>
          )}
          <HeaderLinks>
            {enableSearch && (
              <SearchLink id="ga-nav-search-desktop" href={SEARCH_URL}>
                <SearchSystemIcon />
              </SearchLink>
            )}

            {callToAction && (
              <HeaderButton component="a" id={`${callToAction.id}-desktop`} href={callToAction.url}>
                {callToAction.title}
              </HeaderButton>
            )}
          </HeaderLinks>
        </HeaderContainer>
      </HeaderWrapper>
    </>
  );
};

export const HeaderMenu: React.FC<HeaderProps> = props => {
  const {variation = 'arhi', config, enableSearch = false, hidePhoneNumber = false, isCorporate, invertSmallHeader, withAbsoluteLinks, absoluteBaseUrl, ...otherProps} = props;
  const dataSource = config ? config : variantConfig[variation]({withAbsoluteLinks, absoluteBaseUrl});
  const {id, navAlignment, navLinks} = dataSource;

  return (
    <>
      <HeaderWrapper id={`${id}-menu`} {...otherProps} as="div">
        <HeaderNav aria-label="Main navigation">
          <Container>
            <NavMenu navAlignment={navAlignment}>{navLinks && navLinks.map(link => <NavLinkItem link={link} key={link.id} navLink={NavLink} />)}</NavMenu>
          </Container>
        </HeaderNav>
      </HeaderWrapper>

      <SmallHeaderBelowXl
        enableSearch={enableSearch}
        hidePhoneNumber={hidePhoneNumber}
        variation={variation}
        config={config}
        showMenu
        isCorporate={isCorporate}
        invert={invertSmallHeader}
        withAbsoluteLinks={withAbsoluteLinks}
        absoluteBaseUrl={absoluteBaseUrl}
      />
    </>
  );
};

export const Header: React.FC<HeaderProps> & {HeaderWrapper: typeof HeaderWrapper} & {
  SmallHeader: typeof SmallHeaderBelowXl;
} & {LogoLink: typeof LogoLink} & {HeaderNav: typeof HeaderNav} & {
  HeaderLinks: typeof HeaderLinks;
} & {SearchLink: typeof SearchLink} = ({variation = 'arhi', isCorporate = false, invertSmallHeader = false, withAbsoluteLinks = false, ...props}) => (
  <>
    <HeaderTop variation={variation} isCorporate={isCorporate} invertSmallHeader={invertSmallHeader} withAbsoluteLinks={withAbsoluteLinks} {...props} />
    <HeaderMenu variation={variation} isCorporate={isCorporate} invertSmallHeader={invertSmallHeader} withAbsoluteLinks={withAbsoluteLinks} {...props} />
  </>
);

Header.displayName = 'Header';
Header.HeaderWrapper = HeaderWrapper;
Header.SmallHeader = SmallHeaderBelowXl;
Header.LogoLink = LogoLink;
Header.HeaderNav = HeaderNav;
Header.HeaderLinks = HeaderLinks;
Header.SearchLink = SearchLink;
