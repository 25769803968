import React from 'react';
import styled, {css} from 'styled-components';
import {InfoSystemIcon} from '@nib/icons';
import {Columns, Column, ml, mr, py, breakpoint} from '@nib/layout';
import {UnstyledButton} from '@nib-components/button';
import {colorLighter, colorDarkest, colorDark, copyColor, copyFontSizeSmall} from '@nib-components/theme';
import {InclusionType} from '../Inclusion';
import InclusionIcon from '../InclusionIcon';
import {COVER_TYPES_DESCRIPTIONS} from '../constants';

export interface InclusionButtonProps {
  inclusion: InclusionType;
  hideBottomBorder?: boolean;
  onInclusionClick: (inclusion: InclusionType) => void;
  [key: string]: unknown;
}

export interface InclusionNameAndIconProps {
  name: string;
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const InclusionLi = styled(({hideBottomBorder, ...rest}) => <li {...rest} />)`
  list-style: none;
  font-size: ${copyFontSizeSmall};
`;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const InclusionButton = styled(({hideBottomBorder, ...rest}) => <UnstyledButton {...rest} />)<InclusionButtonProps>`
  display: block;
  width: 100%;
  ${py(2)};
  background-color: transparent;
  ${({hideBottomBorder}) =>
    !hideBottomBorder &&
    css`
      border-bottom: 1px solid var(--themeColorBorder, ${colorLighter});
    `};
  color: var(--themeColorFg, ${copyColor});
  text-align: start;
  line-height: 1.5;
  min-height: 48px;
`;

InclusionButton.displayName = 'InclusionButton';

const FixedWidth32px = styled.div`
  width: 32px;
  ${mr(1)};
  text-align: center;
`;

export const InfoIcon = styled(InfoSystemIcon).attrs({size: 'xs'})`
  display: inline;
  position: relative;
  inset-block-start: 3px;
  ${ml(1)}
  line-height: 1.5;
  color: var(--themeColorFgMuted, ${colorDark});

  ${InclusionButton}:hover &&,
  ${InclusionButton}:focus && {
    color: var(--themeColorFg, ${colorDarkest});
  }
`;

const InclusionName = styled.span`
  line-height: 1.5;
  word-break: break-word;
  hyphens: auto;

  ${breakpoint('mini')`
    hyphens: none;  
  `}
`;

const LastWordAndIcon = styled.span`
  white-space: no-wrap;
  display: inline-flex;
  flex-wrap: wrap;
`;

export const splitLastWordFromName = (name: string): {lastWord: string; everythingElse: string} => {
  const splitName = {
    lastWord: '',
    everythingElse: ''
  };

  if (typeof name === 'string') {
    const words = name.trim().split(' ');
    const lastWord = words[words.length - 1];
    const everythingElse = words.splice(0, words.length - 1);

    splitName.lastWord = lastWord;
    if (everythingElse.length > 0) {
      splitName.everythingElse = everythingElse.join(' ') + ' ';
    }
  }
  return splitName;
};

export const InclusionNameAndIcon: React.FC<InclusionNameAndIconProps> = ({name}) => {
  const splitName = splitLastWordFromName(name);
  return (
    <InclusionName>
      {splitName.everythingElse}
      <LastWordAndIcon>
        {splitName.lastWord}
        <InfoIcon fill="currentColor" />
      </LastWordAndIcon>
    </InclusionName>
  );
};

const Inclusion: React.FC<InclusionButtonProps> = ({inclusion, hideBottomBorder, onInclusionClick}) => {
  return (
    <InclusionLi>
      <InclusionButton hideBottomBorder={hideBottomBorder} onClick={() => onInclusionClick(inclusion)} aria-label={`${inclusion.name}, ${COVER_TYPES_DESCRIPTIONS[inclusion.coveredTypeCode]}`}>
        <Columns collapseBelow="mini">
          <Column width="content">
            <FixedWidth32px>
              <InclusionIcon coveredTypeCode={inclusion.coveredTypeCode} />
            </FixedWidth32px>
          </Column>
          <Column>
            <InclusionNameAndIcon name={inclusion.name} />
          </Column>
        </Columns>
      </InclusionButton>
    </InclusionLi>
  );
};

Inclusion.displayName = 'Inclusion';

export default Inclusion;
